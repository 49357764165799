// ContactForm.tsx

import React from 'react';

interface ContactFormProps {
    formData: {
        name: string;
        email: string;
        message: string;
        phoneNumber: string;
    };
    handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleSubmit: (e: React.FormEvent) => Promise<void>;
    handleReset: () => void;
}

const ContactForm: React.FC<ContactFormProps> = ({ formData, handleChange, handleReset, handleSubmit }) => {
    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
        }}>
            <div className="fields">
                <div className="field half">
                    <label htmlFor="name">Ime</label>
                    <input type="text" name="name" id="name" value={formData.name} onChange={handleChange}/>
                </div>
                <div className="field half">
                    <label htmlFor="email">Email</label>
                    <input type="text" name="email" id="email" value={formData.email} onChange={handleChange}/>
                </div>
                <div className="field half">
                    <label htmlFor="phoneNumber">Broj Telefona</label>
                    <input type="text" name="phoneNumber" id="phoneNumber" value={formData.phoneNumber} onChange={handleChange}/>
                </div>
                <div className="field">
                    <label htmlFor="message">Poruka</label>
                    <textarea name="message" id="message" value={formData.message} onChange={handleChange}></textarea>
                </div>
            </div>
            <ul className="actions">
                <li><input type="submit" value="Pošalji poruku" className="primary"/></li>
                <li><input type="reset" value="Obriši" onClick={handleReset}/></li>
            </ul>
        </form>
    );
};

export default ContactForm;
