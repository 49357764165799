import outdoor_pic_01 from '../images/slika_vani_01.jpg';
import outdoor_pic_02 from '../images/slika_vani_2.jpg';
import indoor_pic_01 from '../images/slika_unutra_1.jpg';
import indoor_pic_02 from '../images/slika_unutra_2.jpg'
import indoor_pic_03 from '../images/slika_unutra_3.jpg'
import indoor_pic_04 from '../images/slika_unutra_4.jpg'
import treehouse_pic_01 from '../images/kucica1.jpg'
import treehouse_pic_02 from '../images/kucica2.jpg'


const Main = () => (
    <div id="main">

        {/* One section */}
        <section id="one" className="tiles">
            <article className="picture">
                <img src={outdoor_pic_01} alt="test" style={{height: '100%', width: '100%', objectFit: 'cover'}}/>
            </article>
            <article className="picture">
                <img src={indoor_pic_01} alt="test" style={{height: '100%', width: '100%', objectFit: 'cover'}}/>
            </article>
            <article className="picture">
                <img src={outdoor_pic_02} alt="test" style={{height: '100%', width: '100%', objectFit: 'cover'}}/>
            </article>
            <article className="picture">
                <img src={indoor_pic_03} alt="test" style={{height: '100%', width: '100%', objectFit: 'fill'}}/>
            </article>
            <article className="picture">
                <img src={indoor_pic_04} alt="test" style={{height: '100%', width: '100%', objectFit: 'fill'}}/>
            </article>
            <article className="picture">
                <img src={indoor_pic_02} alt="test" style={{height: '100%', width: '100%', objectFit: 'cover'}}/>
            </article>
            <article className="picture">
                <img src={treehouse_pic_01} alt="test" style={{height: '100%', width: '100%', objectFit: 'cover'}}/>
            </article>
            <article className="picture">
                <img src={treehouse_pic_02} alt="test" style={{height: '100%', width: '100%', objectFit: 'cover'}}/>
            </article>
        </section>


        <section id="two">
            <div className="inner">

                <header className="major">
                    <h2>Sadržaj Džungle &#127796;</h2>
                </header>
                <p>Inspirirana tropskim džunglama i raznobojnom florom i faunom, ova rođendaonica stvara atmosferu
                    avanture i zabave koja će djetetu i njihovim prijateljima ostati u trajnom sjećanju.</p>
                <p>Dječja rođendaonica "<span className="jungle-text">Džungla</span>" nudi širok spektar zabavnih
                    aktivnosti prilagođenih različitim uzrastima djece.
                    Tu su trampolin, tobogan, pješčanik, ljuljačke, penjalice, nogomet, košarka, game room s Playstation konzolama,
                    društvene igre s zabavnim i edukativnim sadržajem,
                    karaoke party, slikanje, mirni kutak u baldahinu i još mnogo toga!</p>

                  <p>Dodali smo i kućicu na drvetu, koja pruža savršeno mjesto za dječju maštu i igru,
                    dodatno obogaćujući avanturu u našoj džungli. &#127881;</p>
                <p>Osiguran parking za sve goste!</p>
                <ul className="actions">
                    <li><a href={"#contact"} className="button next">Započni avanturu!</a></li>
                </ul>
            </div>
        </section>
    </div>
);


export default Main;