// Contact component

import {Link} from "react-router-dom";
import React, { useState } from 'react';
import ContactForm from "./ContactForm";
import API_BASE_URL from '../config/config';


const Contact = () => {
    // State to manage form data
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        phoneNumber: '',
    });

    const [submitStatus, setSubmitStatus] = useState({
        success: false,
        error: false,
    });


    // Handle form submission
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_BASE_URL}/send-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                console.log('Email sent successfully');

                setTimeout(() => {
                    setSubmitStatus({ success: false, error: false });
                }, 10000);
                handleReset();
                setSubmitStatus({success: true, error: false})
            } else {
                setSubmitStatus({success: false, error: true})

                setTimeout(() => {
                    setSubmitStatus({ success: false, error: false });
                }, 10000);

                console.error('Error sending email');
            }
        } catch (error) {
            setSubmitStatus({success: false, error: true})

            setTimeout(() => {
                setSubmitStatus({ success: false, error: false });
            }, 10000);

            console.error('Error sending email:', error);
        }
        console.log('Form data submitted:', e);
    };

    // Handle form input changes
    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle form reset
    const handleReset = () => {
        setFormData({
            name: '',
            email: '',
            message: '',
            phoneNumber: '',
        });
    };

return (
    <section id="contact">
        <div className="inner">
            <section>
                <ContactForm
                    formData={formData}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    handleReset={handleReset}
                />
                {submitStatus.success && (
                    <div style={{ color: 'green', fontWeight: "bold" }}>
                        Poruka je uspješno poslana!
                    </div>
                )}
                {submitStatus.error && (
                    <div style={{ color: 'red', fontWeight: "bold" }}>
                        Dogodila se greška prilikom slanja poruke. Pokušajte poslije ponovno.
                    </div>
                )}
            </section>
            <section className="split">
                <section>
                    <div className="contact-method">
                        <span className="icon solid alt fa-envelope"></span>
                        <h3>Email</h3>
                        <Link to="https://maps.app.goo.gl/C58HqzGoq1UjY3xx5">dzunglarodendaonica@gmail.com</Link>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon solid alt fa-phone"></span>
                        <h3>Telefon</h3>
                        <span>univ. mag. praesc. educ. Lucija Raguž</span><br/>
                        <span>(+385) 91 596 1090</span>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon solid alt fa-home"></span>
                        <h3>Adresa</h3>
                        <span>Rakarska 72A<br/>
										Velika Gorica<br/>
										 Hrvatska, 10410</span>
                    </div>
                </section>
            </section>
        </div>
    </section>
);
};

export default Contact;