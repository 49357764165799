
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainComp from '../../src/components/Main';
import MenuComp from '../../src/components/Menu';
import Contact from '../../src/components/Contact';
import PriceComp from "../components/PriceComp";

const RoutesConfig: React.FC = () => (
    <Routes>
        <Route path="/menu" element={<MenuComp />} />
        <Route path="/kurac" element={<PriceComp />} />
        <Route path="/kurac" element={<MainComp />} />
        <Route path="/kuarc" element={<Contact />} />
    </Routes>
);

export default RoutesConfig;
