

const PriceComp = () => (
    <div id="main">
        {/* One section */}
        <section id="price" className="tiles">
            <article className={"rest"}>
                <span className="image"></span>
                <header className="major">
                    <h3><a href="#contact" className="link">Paket Panda &#128060;</a></h3>
                    <li> Hakuna Matata pozivnice</li>
                    <br/>
                    <li> 2h zabave</li>
                    <br/>
                    <li> do 15 djece</li>
                    <br/>
                    <li> Odgojitelj + animator</li>
                    <br/>
                    <li> Grickalice, keksi i negazirani sokovi</li>
                    <br/>
                    <li> Posebni poklon za slavljenika</li>
                    <br/>
                    <li> Osnovni poklon za svakog pozvanog gosta</li>
                    <br/>
                    <li> Mogućnost dodatka pizza i/ili rođendanske torte (25€, 20€)</li>
                    <br/>
                    <h4>175,00€ (kotizacija 50,00€)</h4>
                </header>
            </article>
            <article className={"rest"}>
                <span className="image"></span>
                <header className="major">
                    <h3><a href="#contact" className="link">Paket Slon  &#128024;</a></h3>
                    <li> Hakuna Matata pozivnice</li>
                    <br/>
                    <li> 2h zabave</li>
                    <br/>
                    <li> do 25 djece</li>
                    <br/>
                    <li> Odgojitelj + animator</li>
                    <br/>
                    <li> Grickalice, keksi i negazirani sokovi</li>
                    <br/>
                    <li> Posebni poklon za slavljenika</li>
                    <br/>
                    <li> Osnovni poklon za svakog pozvanog gosta</li>
                    <br/>
                    <li> Mogućnost dodatka pizza i/ili rođendanske torte (25€, 20€)</li>
                    <br/>
                    <h4>195,00€ (kotizacija 60,00€)</h4>
                </header>
            </article>
            <article className={"rest"}>
                <span className="image"></span>
                <header className="major">
                    <h3><a href="#contact" className="link">Paket Žirafa  &#129426;</a></h3>
                    <li> Hakuna Matata pozivnice</li>
                    <br/>
                    <li> 3h zabave</li>
                    <br/>
                    <li> do 15 djece</li>
                    <br/>
                    <li> Odgojitelj + animator</li>
                    <br/>
                    <li> Grickalice, keksi i negazirani sokovi</li>
                    <br/>
                    <li> Posebni poklon za slavljenika</li>
                    <br/>
                    <li> Osnovni poklon za svakog pozvanog gosta</li>
                    <br/>
                    <li> Mogućnost dodatka pizza i/ili rođendanske torte (25€, 20€)</li>
                    <br/>
                    <h4>195,00€ (kotizacija 60,00€)</h4>
                </header>
            </article>
            <article className={"rest"}>
                <span className="image"></span>
                <header className="major">
                    <h3><a href="#contact" className="link">Paket Lav &#129409;</a></h3>
                    <li> Hakuna Matata pozivnice</li>
                    <br/>
                    <li> 3h zabave</li>
                    <br/>
                    <li> do 25 djece</li>
                    <br/>
                    <li> Odgojitelj + animator</li>
                    <br/>
                    <li> Grickalice, keksi i negazirani sokovi</li>
                    <br/>
                    <li> Posebni poklon za slavljenika</li>
                    <br/>
                    <li> Osnovni poklon za svakog pozvanog gosta</li>
                    <br/>
                    <li> Mogućnost dodatka pizza i/ili rođendanske torte (25€, 20€)</li>
                    <br/>
                    <h4>215,00€ (kotizacija 70,00€)</h4>
                </header>
            </article>
            <article className={"rest"}>
                <span className="image"></span>
                <header className="major">
                    <h3><a href="#contact" className="link">Paket Movie Night &#128250;</a></h3>
                    <li>Gledanje filma po dogovoru</li>
                    <br/>
                    <li>2h zabave</li>
                    <br/>
                    <li>do 18 djece</li>
                    <br/>
                    <li>Odgojitelj + animator</li>
                    <br/>
                    <li>Kokice, gazirani i negazirani sokovi</li>
                    <br/>
                    <li>Posebni poklon za slavljenika</li>
                    <br/>
                    <li>Osnovni poklon za svakog pozvanog gosta</li>
                    <br/>
                    <h4>170,00€ (kotizacija 60,00€)</h4>
                </header>
            </article>
        </section>
    </div>
);


export default PriceComp;