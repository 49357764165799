import React from 'react';
import './App.css';
import MainComp from './components/Main';
import Header from './components/Header';
import Banner from './components/Banner';
import Contact from './components/Contact';
import Footer from './components/Footer';
import RoutesConfig from "./routes/RoutesConfig";
import PriceComp from "./components/PriceComp";
import Note from "./components/Note";
import { useEffect } from "react";
import CookieConsent, {
    getCookieConsentValue,
    Cookies,
} from "react-cookie-consent";
import { initGA } from "./utils/ga-utils";
import JSConfetti from 'js-confetti';


function App() {

    const handleAcceptCookie = async () => {
        if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
            initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
        }

        const jsConfetti = new JSConfetti()
        // Add confetti effect here
        for (let i = 0; i < 1; i++) {
            await jsConfetti.addConfetti({
                emojis: ['🍪', '🍦', '🎉', '🎈' ],
                confettiNumber: 150,

            })
            await new Promise(r => setTimeout(r, 1000));
        }


    };

    const handleDeclineCookie = () => {
        Cookies.remove("_ga");
        Cookies.remove("_gat");
        Cookies.remove("_gid");
    };

    useEffect(() => {
        const isConsent = getCookieConsentValue();
        if (isConsent === "true") {
            handleAcceptCookie();
        }
    }, []);


    const birthdayMessage = (
        <>
            🍪 Ova stranica koristi kolačiće  kako bi tvoj rođendanski doživljaj bio još bolji! Klikom na "Prihvati" pristaješ na našu politiku kolačića i dobivaš porciju virtualnog sladoleda.{' '}
            <span role="img" aria-label="Ice Cream Emoji">
                🍦
            </span>{' '}
            Ali bez brige, možeš odbiti ako si na virtualnoj bezšećernoj dijeti!
        </>
    );


    return (
        <div id="wrapper">
            {/* Include components in the desired order */}
            <Header />
            <Banner />
            <MainComp />
            <PriceComp />
            <Note />
            <Contact />
            <Footer />
            <RoutesConfig />
            <CookieConsent
                onAccept={handleAcceptCookie}
                onDecline={handleDeclineCookie}
                enableDeclineButton flipButtons={true}
                declineButtonText={"Odbij"}
                location="bottom"
                buttonText="Prihvati!"
                declineButtonClasses={"btn-consent-decline"}
                buttonClasses={"btn-consent-accept"}
                cookieName="analytics-consent-cookie"
                containerClasses={"container-consent-bar"}
                expires={150}
            >
               <div>{birthdayMessage}</div>
            </CookieConsent>
        </div>
    );
}

export default App;
