// Menu component

import {Link, useNavigate } from "react-router-dom";
import {useState} from "react";

const Menu = () => {
    const navigate = useNavigate();
    const [menuVisible, setMenuVisible] = useState(false);

    const handleLinkClick = () => {
        navigate('/');
    }


    return (
        <body className="is-menu-visible">
    <nav id="menu">
        <div className="inner">
            <ul className="links">
                <li>
                    <Link to="/" onClick={handleLinkClick}>
                        Početak
                    </Link>
                </li>
                <li>
                    <a href="#main" onClick={handleLinkClick}>
                        Galerija
                    </a>
                </li>
                <li>
                    <a href="#price" onClick={handleLinkClick}>
                        Ponuda
                    </a>
                </li>
                <li>
                    <a href="#contact" onClick={handleLinkClick}>
                        Kontakt
                    </a>
                </li>
            </ul>
        </div>
        <a className="close" href="/">
            Close
        </a>
    </nav>
        </body>
    );
    };

export default Menu;