// Footer component

const Footer = () => (
    <footer id="footer">
        <div className="inner">
            <ul className="icons">
                <li><a href="https://www.facebook.com/profile.php?id=61550861720751" rel="noreferrer" target={"_blank"} className="icon brands alt fa-facebook-f">
                    <span className="label">Facebook</span></a>
                </li>
                <li><a href="https://www.instagram.com/rodendaonica.dzungla?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                       rel="noreferrer" target={"_blank"} className="icon brands alt fa-instagram"><span className="label">Instagram</span></a>
                </li>
                <li><a href="https://maps.app.goo.gl/C58HqzGoq1UjY3xx5"
                       rel="noreferrer" target={"_blank"} className="icon brands alt fa-google"><span className="label">Google</span></a></li>
            </ul>
            <ul className="copyright">
                <li>&copy; Dječja Rođendaonica Džungla</li>
                <li>Design: <a href="https://html5up.net">HTML5 UP</a></li>
            </ul>
        </div>
    </footer>
);


export default Footer;